import React, { useState, useEffect, useContext } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Spinner from '../components/Spinner.js';
import CampContext, { Steps } from '../contexts/CampContext.js';
import { withUser } from '../providers/UserProvider.js';
import AppStep from '../components/AppStep';
import isProduction from '../lib/isProduction';

// Initialize Stripe outside the component
const stripe_key = isProduction ? "pk_live_pb5ZfipL8aYKsKgM3KvvMRpC00LqgIdXpg" : "pk_test_z0kOrMrHfPd6rfMq0vCl8r7r006u7iN76w"
const stripePromise = loadStripe(stripe_key);

const PaymentStepForm = ({ user, clientSecret }) => {
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState(false);

  const context = useContext(CampContext);
  const stripe = useStripe();
  const elements = useElements();

  const needPayment = () => {
    const { scanCredits = 0 } = user;
    const { pack, wantsToPay } = context;
    return (pack || !scanCredits || wantsToPay);
  };

  const submit = async (e) => {
    if (e) e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setPending(true);

    let token = null;

    if (needPayment()) {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + '/payment-success',
        },
        redirect: 'if_required'
      });

      if (error) {
        setErrorMessage(`Error: ${error.message}`);
        setPending(false);
        setProcessing(false);
        return;
      }
      token = paymentIntent;
    }

    try {
      const json = await context.onCheckout(token);
      if (json.error) {
        setErrorMessage(json.error);
        setPending(false);
        setProcessing(false);
        return;
      }

      setErrorMessage(null);
      setPending(false);
      setProcessing(false);

      // Update user data
      user.onJson(json.user);

      // Go to thank you step
      context.onStep(Steps.THANKS);
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setPending(false);
      setProcessing(false);
    }
  };

  const s = num => num === 1 ? '' : 's';

  const { pack, wantsToPay } = context;
  const { scanCredits = 0 } = user;

  const cta = wantsToPay || !scanCredits ? 
    `Pay $${context.getPrice()} to Start Scan` :
    "Use (1) to Start Scan";

  return (
    <form onSubmit={submit}>
      <div>
        {needPayment() && ( <label htmlFor="csa-app-pay">Enter your payment information</label> )}
        {errorMessage && <p className="checkoutError">{errorMessage}</p>}
        {needPayment() && (
          <div className="stripe-holder">
            <PaymentElement 
              options={{
                layout: { type: 'tabs', defaultCollapsed: false },
                // Configure which fields appear
                fields: {
                  billingDetails: 'auto'
                }
              }}
            />
          </div>
        )}
        <p className="form-text">
          <i id="csa-app-icon-lock"></i> Your information is secure
        </p>
      </div>

      <div id="csa-app-last" className="csa-app-step">
        <button 
          className="btn btn-lg btn-primary" 
          type="submit"
          disabled={!stripe || pending || !context.canCheckout() || processing}
        >
          {
            // [x] JOHN D 
            // [x] Button Text Options 
            // [x] Pay ${price} to Start Scan (default: single specific, pack purchase, matrix)
            // [x] Use (1) to Start Scan (IF user has chosen to use a scan from a pack)
          }
          <Spinner loading={pending} /> {cta}
        </button>
      </div>

      {pack && (
        <div className="csa-app-payment-note">
          <p>
            <strong>Your purchase includes a {pack} Pack of 3 Minute Scans.</strong><br/>
            The first of these Scans will be used upon purchase.<br/>
            You will have {pack-1} Scan{s(pack-1)} added to your Account.
          </p>
        </div>
      )}

      {
        // [x] JOHN D 
        // IF a User is USING a Scan in a pre-existing Pack 
        // Show the message below
      }
      {!wantsToPay && scanCredits > 0 && (
        <div className="csa-app-payment-note">
          <p>
            <strong>Use (1) of your Pre-paid Scans.</strong><br/>
            You will have {scanCredits-1} Scan{s(scanCredits-1)} remaining in your Account.
          </p>
        </div>
      )}
    </form>
  );
};

const PaymentStep = ({ user }) => {
  const [clientSecret, setClientSecret] = useState("");
  const context = useContext(CampContext);

  useEffect(() => {
    fetchPaymentIntent();
  }, []);

  const fetchPaymentIntent = async () => {
    try {
      const response = await fetch('/bot-backend/api/create_payment_intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          amount: context.getPrice() * 100, 
          currency: 'usd' ,
          email: context.email,
          payment_method_types: ['card']
        }),
      });
      const data = await response.json();

      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      } else {
        throw new Error('Failed to create payment intent');
      }
    } catch (error) {
      console.error('Error fetching payment intent:', error);
    }
  };

  return (
    <div id="csa-app-checkout">
      <AppStep id="csa-app-payment" idx={Steps.PAYMENT}>
        {clientSecret ? (
          <Elements 
            stripe={stripePromise} 
            options={{
              clientSecret,
              appearance: { theme: 'stripe' }
            }}
          >
            <PaymentStepForm user={user} clientSecret={clientSecret} />
          </Elements>
        ) : (
          <div className="loading-payment">
            <Spinner loading={true} /> Loading payment form...
          </div>
        )}
      </AppStep>
    </div>
  );
};

const StripePaymentStep = withUser(PaymentStep);

export { StripePaymentStep, PaymentStep };