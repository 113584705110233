import React, { Component, useContext } from 'react';
import UserContext from '../contexts/UserContext.js';
import { get, post, patch, destroy } from '../ajax.js';
import { subscribe } from 'superagent';

export default class UserProvider extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: this.isLoggedIn,
      isUnconfirmed: this.isUnconfirmed,
      onCreateAccount: this.onCreateAccount,
      onUpdateAccount: this.onUpdateAccount,
      onLogin: this.onLogin,
      onLogout: this.onLogout,
      onReset: this.onReset,
      onJson: this.onJson,
      onBuyPack: this.onBuyPack,
      onPurchaseMembership: this.onPurchaseMembership,
      onCancelMembership: this.onCancelMembership,
      onUpdateMembership: this.onUpdateMembership,
      initialized: false,
      confirmed: null,
    }
  }

  isLoggedIn = () => {
    return !!this.state.email;
  }

  isUnconfirmed = () => {
    return this.state.confirmed !== null && !this.state.confirmed;
  }

  onJson = (user) => {
    this.setState(Object.assign({}, { initialized: true }, user));
  }

  onLogin = (user, pass) => {
    return post('/bot-backend/api/login', { username: user, password: pass }).then(json => {
      if (!json.errors) {
        this.setState(Object.assign({}, { initialized: true }, json));
      }
      return json;
    });
  }

  onLogout = () => {
    let rm = [ "username", "email", "firstname", "lastname", "id", "phone" ];
    this.setState(ps => { 
      rm.forEach(key => delete ps[key]);
      return ps;
    });
    return post('/bot-backend/api/logout').then(json => {
      return json;
    });
  }

  onReset = (user) => {
    return post('/bot-backend/api/forgot', { username: user }).then(json => {
      return json;
    });
  }

  onCreateAccount = (data) => {
    return post('/bot-backend/api/user', data).then(json => {
      console.log("onCreateAccount: got response = ", json);
      this.setState(json);
      return json;
    });
  }

  onUpdateAccount = (data) => {
    return patch('/bot-backend/api/user', data).then(json => {
      console.log("onUpdateAccount: got response = ", json);
      this.setState(json);
      return json;
    });
  }

  onBuyPack = async (token, pack) => {
    console.log("onBuyPack: token = ", token, "pack=", pack);

    const data = {
      token: token ? token.id : null,
      pack
    }

    const json = await post('/bot-backend/api/pack_checkout', data)
    console.log("onBuyPack: got response = ", json);
    this.setState(json.user);
    return json;
  }

  onPurchaseMembership = async (data) => {
    return post('/bot-backend/api/subscription', data).then(json => {
      console.log("onPurchaseMembership: got response = ", json);
      this.setState(json);
      return json;
    });
  }

  onCancelMembership = async (data) => {
    return post('/bot-backend/api/cancel_subscription', data).then(json => {
      console.log("onCancelMembership: got response = ", json);
      this.setState(json);
      return json;
    });
  }

  onUpdateMembership = async (data) => {
    return patch('/bot-backend/api/update_subscription_plan', data).then(json => {
      console.log("onUpdateMembership: got response = ", json);
      this.setState(json);
      return json;
    });
  }

  componentDidMount() {
    console.log("UserProvider.componentDidMount");
    if (!this.state.initialized) {
      let url = '/bot-backend/api/user';
      let u = new URLSearchParams(window.location.search);
      if (u.has("key")) url += "?key=" + escape(u.get("key"));
      get(url).then(json => {
        let key = u.has("key") ? { key: u.get("key") } : {}
        this.setState({ 
          initialized: true,
          ...json,
          ...key
        })
      });
    }
  }

  render() {
    return <UserContext.Provider value={this.state}>{this.state.initialized && this.props.children}</UserContext.Provider>
  }
}

const withUser = Component => (
  props => (
    <UserContext.Consumer>
      {value => <Component user={value} {...props} />}
    </UserContext.Consumer>
  )
)

export { withUser };