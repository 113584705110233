import React, { useState, useContext, useEffect } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Spinner from '../../components/Spinner.js';
import MembershipContext, { Steps } from '../../contexts/MembershipContext.js';
import { withUser } from '../../providers/UserProvider.js';
import MembershipAppStep from '../../components/MembershipAppStep';
import { loadStripe } from '@stripe/stripe-js';
import isProduction from '../../lib/isProduction';

// Initialize Stripe outside the component
const stripe_key = isProduction ? "pk_live_pb5ZfipL8aYKsKgM3KvvMRpC00LqgIdXpg" : "pk_test_z0kOrMrHfPd6rfMq0vCl8r7r006u7iN76w"
const stripePromise = loadStripe(stripe_key);

const PaymentStepForm = ({ user, clientSecret }) => {
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState(false);

  const context = useContext(MembershipContext);
  const stripe = useStripe();
  const elements = useElements();

  const submit = async (e) => {
    if (e) e.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setPending(true);

    let token = null;

    const stripe_response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + '/membership',
      },
      redirect: 'if_required'
    });

    console.log("STRIPE: GOT RESPONSE: ", stripe_response);
    const { error } = stripe_response;

    if (error) {
      setErrorMessage(`Error: ${error.message}`);
      setPending(false);
      return;
    }
    const paymentIntent = stripe_response.paymentIntent;
    token = paymentIntent.payment_method;

    let data = {
      token,
      email: context.email
    }

    const json = await context.onCheckout(data);
    if (json.error) {
      setErrorMessage(json.error);
      setPending(false);
      return;
    }

    setErrorMessage(null);
    setPending(false);

    user.onJson(json.user);
    context.onStep(Steps.THANKS);
  };

  const onToggle = (e) => {
    context.onToggleConsent();
  };

  const cta = context.subscription_type
    ? `Pay $${context.getPrice()} to Start Membership`
    : 'Select Membership Plan';

  return (
    <div id="csa-app-checkout">
      <MembershipAppStep id="csa-app-payment" idx={Steps.PAYMENT}>
        <div>
          <label htmlFor="csa-app-pay">Enter your payment information</label>
          {errorMessage && <p className="checkoutError">{errorMessage}</p>}
          <div className="stripe-holder">
            <PaymentElement 
              options={{
                layout: { type: 'tabs', defaultCollapsed: false },
                // Configure which fields appear
                fields: {
                  billingDetails: 'auto'
                }
              }}
            />
          </div>
          <p className="form-text">
            <i id="csa-app-icon-lock"></i> Your information is secure
          </p>

          <div
            key="consent"
            className="custom-control custom-control-lg custom-checkbox d-inline-flex my-3 px-4"
          >
            <input
              id="consent"
              type="checkbox"
              className="custom-control-input"
              checked={context.haveConsent()}
              onClick={onToggle}
            />
            <label className="custom-control-label" htmlFor="consent">
              {context.subscription_type
                ? `I consent to a ${context.subscription_type} charge of $${context.getPrice()}`
                : 'Select Membership Plan'}
            </label>
          </div>
        </div>
        <div id="csa-app-last" className="csa-app-step">
          <button
            className="btn btn-lg btn-primary mb-3"
            onClick={submit}
            disabled={!stripe || pending || !context.canCheckout() || processing}
          >
            <Spinner loading={pending} /> {cta}
          </button>
          <p className="form-text">
            <em>Cancel at any time.</em>
          </p>
        </div>
      </MembershipAppStep>
    </div>
  );
}

const PaymentStep = ({ user }) => {
  const [clientSecret, setClientSecret] = useState("");
  const context = useContext(MembershipContext);

  useEffect(() => {
    fetchPaymentIntent();
  }, []);

  const fetchPaymentIntent = async () => {
    try {
      const response = await fetch('/bot-backend/api/initialize_subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          amount: context.getPrice(),
          subscription_type: context.subscription_type,
          currency: 'usd' ,
          email: context.email,
          payment_method_types: ['card'],
          request_page: 'campsite-pro'
        }),
      });
      
      const data = await response.json();

      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      } else if (data.message === 'Login to change subscription') {
        await fetch('/bot-backend/api/logout', { method: 'POST', headers: { 'Content-Type': 'application/json' } })
        window.location.href = "/login?success=This+user+has+already+a+subscription.+Please+log+in+to+your+account+if+you+want+to+change+the+subscription.";
      } else {
        throw new Error('Failed to create subscription');
      }
    } catch (error) {
      console.error(`Subscription creation failed: ${error.response.data.error || error.message}`);
    }
  };

  return (
    <div id="csa-app-checkout">
      <MembershipAppStep id="csa-app-payment" idx={Steps.PAYMENT}>
        {clientSecret ? (
          <Elements 
            stripe={stripePromise} 
            options={{
              clientSecret,
              appearance: { theme: 'stripe' },
            }}
          >
            <PaymentStepForm user={user} clientSecret={clientSecret} />
          </Elements>
        ) : (
          <div className="loading-payment">
            <Spinner loading={true} /> Loading payment form...
          </div>
        )}
      </MembershipAppStep>
    </div>
  );
};

// Export the component with user context
const StripePaymentStep = withUser(PaymentStep);

export { StripePaymentStep, PaymentStep };
