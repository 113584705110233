import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../contexts/UserContext';
import CampProvider from '../providers/CampProvider';
import BlockUI from 'react-block-ui';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import C from 'classnames';
import Spinner from '../components/Spinner.js';
import { withUser } from '../providers/UserProvider.js';
import isProduction from '../lib/isProduction';

// Initialize Stripe outside the component
const stripe_key = isProduction ? "pk_live_pb5ZfipL8aYKsKgM3KvvMRpC00LqgIdXpg" : "pk_test_z0kOrMrHfPd6rfMq0vCl8r7r006u7iN76w"
const stripePromise = loadStripe(stripe_key);

const SubscriptionForm = ({ user, clientSecret, subscriptionType, email, handleToUpdate }) => {
  const context = useContext(UserContext);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [consent, setConsent] = useState(false);
  const [price, setPrice] = useState(subscriptionType === 'month' ? 4 : 40);
  const stripe = useStripe();
  const elements = useElements();

  const onToggle = e => {
    setConsent(!consent);
  }

  const onLogout = (e) => {
    e.preventDefault();
    context.onLogout().then(json => {
      window.location.href = "/login?success=You're+now+logged+out";
    });
  }

  const submit = async (e) => {
    setPending(true);
    let token = null;
    const stripe_response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + '/membership',
      },
      redirect: 'if_required'
    });

    console.log("STRIPE: GOT RESPONSE: ", stripe_response);
    const { error } = stripe_response;
    if (error) {
      this.setState({error: `Error: ${error.message}`, pending: false});
      return;
    }
    const paymentIntent = stripe_response.paymentIntent;
    token = paymentIntent.payment_method;

    let data = {
      token, // 'pm_1RBcVbJtt4ltxGlNU37KKDGs'
      email: email
    }

    const json = await this.context.onPurchaseMembership(data);
    if (json.ok) {
      const successMessage = 'Membership Successfully Created!'
      handleToUpdate(json.user, successMessage);
      user.onJson(json.user);
      setError(null);
      setPending(false);
    } else if (json.error) {
      setError(json.error);
      setPending(false);
      return;
    }

    window.location.href = "/membership";
  }

  return(
    <>
      <div className="text-center mt-3">
        <div id="csp-email-campsite-pro">
          <h2>Your e-mail address</h2>
          <p><span><strong>{email}</strong></span> <a href="#" onClick={onLogout}>Logout to change</a></p>
        </div>

        <div id="csp-payment-campsite-pro">
          <h2>Enter your payment information</h2>

          <div className="stripe-holder">
            <PaymentElement 
              options={{
                layout: { type: 'tabs', defaultCollapsed: false },
                // Configure which fields appear
                fields: {
                  billingDetails: 'auto'
                }
              }}
            />
          </div>
          <p className="form-text"><i id="csa-app-icon-lock"></i> Your information is secure</p>

          <div key="consent" id="csp-payment-campsite-pro-consent" className="custom-control custom-control-lg custom-checkbox d-inline-flex">
            <input id="consent" type="checkbox" className="custom-control-input" onClick={onToggle} />
            <label className="custom-control-label" htmlFor="consent">{subscriptionType ? `I consent to a ${subscriptionType} charge of $${price}` : 'Select Membership Plan'}</label>
          </div>
        </div>

        <div id="csa-app-last">
          <button className="btn btn-lg btn-primary" onClick={submit} disabled={!(subscriptionType && consent)} ><Spinner loading={pending} />{subscriptionType ? `Pay $${price} to Start Membership` : 'Select Membership Plan'}</button>
          <p className="form-text mt-3 mb-5"><em>Cancel at any time.</em></p>
          <p><small>*Excluding embedded afflicate links, promos and offers, as well as direct placement ads.</small></p>
        </div>
      </div>
    </>
  )
}

const HaveSubscription = ({ user, subscription, handleToUpdate }) => {
  const context = useContext(UserContext);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);

  const onCancelMembership = async (e) => {
    e.preventDefault();

    let data = {
      email: context.email,
      subscription_type: context.subscription.subscription_type
    };

    const json = await context.onCancelMembership(data);
    if (json.ok) {
      const successMessage = 'Your Campsite PRO Membership has been Cancelled';
      user.onJson(json.user);
      handleToUpdate(json.user, successMessage);
    } else if (json.error) {
      setError(json.error);
      setPending(false);
      return;
    }

    // Redirect to membership page
    window.location.href = "/membership";
  };

  const onChangePlan = async () => {
    let data = {
      email: context.email,
      subscription_type: context.subscription.subscription_type === 'month' ? 'yearly' : 'monthly',
      price: context.subscription.subscription_type === 'month' ? 40 : 4
    };

    const json = await context.onUpdateMembership(data);
    if (json.ok) {
      const successMessage = 'Membership Plan Successfully Updated!';
      user.onJson(json.user);
      handleToUpdate(json.user, successMessage);
    } else if (json.error) {
      setError(json.error);
      setPending(false);
      return;
    }

    // Redirect to membership page
    window.location.href = "/membership";
  };

  const planPrice = (subscription_type) => {
    return subscription_type === 'month' ? '$4 per month' : '$40 per year';
  };

  const changePlan = (subscription_type) => {
    if (subscription_type === 'month') {
      return (
        <div>
          <a className="csa-app-membership-change-plan" onClick={onChangePlan}>Change to Yearly</a> <span>({planPrice('year')})</span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="csa-app-membership-change-plan" onClick={onChangePlan}>Change to Monthly</a> <span>({planPrice('month')})</span>
        </div>
      );
    }
  };

  const toLocaleDateString = (membership_date) => {
    const date = new Date(membership_date);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const nextPaymentDate = (membership_date = null) => {
    let date = new Date(subscription.last_paid_at);
    if (subscription.subscription_type === 'month') {
      date.setMonth(date.getMonth() + 1);
    } else {
      date.setFullYear(date.getFullYear() + 1);
    }

    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <div>
      <div id="csa-users-header" className="d-flex flex-wrap justify-content-center align-items-center">
        <h1 className="mx-0"><strong>Campsite PRO</strong></h1>
      </div>
      <div id="csp-sub-holder">
        <h2 className="text-center">Your Subscription</h2>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div>
            <h3 className="text-capitalize">
              {subscription.subscription_type}ly
              <a href="" onClick={(e) => { e.preventDefault(); }}>{planPrice(subscription.subscription_type)}</a>
            </h3>
            <p><small>Subscribed {toLocaleDateString(subscription.purchased_at)}</small></p>
          </div>
          <div>
            <strong>{changePlan(subscription.subscription_type)}</strong>{context.username}
          </div>
        </div>
        <div className="d-flex text-left mt-4" style={{ justifyContent: "space-between" }}>
          <div>
            <h4>Next Bill Date</h4>
            <p><strong>{nextPaymentDate()}</strong></p>
          </div>
          <div>
            <a className="btn btn-sm btn-light" href="" onClick={onCancelMembership}>Cancel Subscription</a>
          </div>
        </div>
      </div>
      <div id="csp-pymts-holder">
        <div className="text-center"><h2>Recent Payments</h2></div>
        {(subscription.payment_history || []).length > 0 && (
          <div className="csa-scan-alerts">
            <ul>
              {subscription.payment_history.map((payment, index) => <li key={index}>{payment}</li>)}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const CampsiteProPage = () => {
  const context = useContext(UserContext);
  const accountVars = [
    "username", "firstname", "lastname", "phone", "email", "subscription",
    ...CampProvider.getFilterVars()
  ];

  const [blocking, setBlocking] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [subscription, setSubscription] = useState(null);
  const [accountData, setAccountData] = useState({});
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    let data = {};
    accountVars.forEach(v => data[v] = context[v] || "");

    let status = '';
    if (context) {
      if (data.user) {
        status = data.user.subscription.status;
      } else if (data.subscription) {
        status = data.subscription.status;
      } else if (context.subscription) {
        status = context.subscription.status;
      }
    }

    setAccountData(data);
    setSubscriptionStatus(status);
    setSubscription(context.subscription || data.subscription);
  }, []);

  const handleToUpdate = (user, successMessage) => {
    setSubscription(user.subscription);
  };

  const fetchPaymentIntent = async (type) => {
    try {
      const response = await fetch('/bot-backend/api/initialize_subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          amount: type === 'month' ? 4 : 40,
          subscription_type: type + 'ly',
          currency: 'usd' ,
          email: context.email,
          payment_method_types: ['card']
        }),
      });
      const data = await response.json();
      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      } else if (data.message !== null) {
        window.location.href = "/membership";
      }
      else {
        throw new Error('Failed to create subscription');
      }
    } catch (error) {
      console.error(`Subscription creation failed: ${error.response.data.error || error.message}`);
    }
  };

  const subscriptionClicked = async (element, type) => {
    const parent = element.target.parentNode;
    parent.classList.toggle("selected");
    parent.classList.toggle("d-inline-block");

    fetchPaymentIntent(type);
    setSubscriptionType(parent.classList.contains('selected') ? type : null);
    setSubscriptionSelected(true);
  };

  const isHaveSubscription = () => {
    if (context.user) {
      return context.user.subscription.status === "active";
    } else if (subscription) {
      return subscription.status === "active";
    }
    return false;
  };

  const isSubscriptionCanceled = () => {
    if (context.user) {
      return context.user.subscription.status === "canceled";
    } else if (subscription) {
      return subscription.status === "canceled";
    }
    return false;
  };

  const currentSubscriptionPeriod = () => {
    let date = new Date(subscription.last_paid_at);
    if (subscription.subscription_type === 'month') {
      date.setMonth(date.getMonth() + 1);
    } else {
      date.setFullYear(date.getFullYear() + 1);
    }

    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const validClientSercet = () => {
    return clientSecret !== ""
  }

  return (
    <>
      {isHaveSubscription() && (
        <HaveSubscription
          user={context}
          subscription={subscription}
          handleToUpdate={handleToUpdate}
        />
      )}
      {isSubscriptionCanceled() && (
        <>
          <div className="alert alert-info">
            Your current subscription will not be renewed after the current period. You can re-subscribe to Campsite Pro at any time.
            <h3 className="text-capitalize">
              <div className='d-flex justify-content-between'>
                <div>
                  <span>Current Plan: {subscription.subscription_type + "ly"} </span>
                </div>
                <div>
                  <span>Current Period Ends In: </span>
                  <a href="" onClick={(e) => { e.preventDefault(); }}>{currentSubscriptionPeriod()}</a>
                </div>
              </div>
            </h3>
          </div>
        </>
      )}
      {!isHaveSubscription() && (
        <BlockUI tag="div" blocking={blocking}>
          <div className="text-center">
            <div id="csa-users-header" className="d-flex flex-wrap justify-content-center align-items-center">
              <h1 className="mx-0">Upgrade Account to <strong>Campsite PRO</strong></h1>
            </div>
            <div id="csp-user-campsite-pro">
              <h2>Discover Your Next Campsite with Added Benefits</h2>

              <div id="csp-benefits" className="d-flex justify-content-center">
                <div>
                  <div className="csp-benefit-icon"><i className="csp-benefit-icon-ad-free"></i></div>
                  <h3><span>Ad Free Experience</span> on campsitephotos.com*</h3>
                </div>
                <div>
                  <div className="csp-benefit-icon"><i className="csp-benefit-icon-discount"></i></div>
                  <h3><span>20% Discount</span> on campsite availability alerts</h3>
                </div>
                <div>
                  <div className="csp-benefit-icon"><i className="csp-benefit-icon-small-biz"></i></div>
                  <h3><span>Support a Small Business</span> &amp; help fund more photo trips</h3>
                </div>
              </div>

              <h2>Select your payment option</h2>

              <div id="csa-scan-type-btns" className="row justify-content-center">
                <div className={C(
                  "col-auto text-center subscription_type",
                  { 'd-inline-block': subscriptionType === "year" },
                  { selected: subscriptionType === "month" },
                )}>
                  <a href="" value={"month"} className="btn btn-secondary" onClick={(e) => {e.preventDefault(); subscriptionClicked(e, 'month'); }}>$4 PER MONTH</a>
                  <p className="form-text">Great Value</p>
                </div>
                <div className={C(
                  "col-auto text-center subscription_type",
                  { 'd-inline-block': subscriptionType === "month" },
                  { selected: subscriptionType === "year" },
                )}>
                  <a href="" value={"year"} className="btn btn-secondary" onClick={(e) => {e.preventDefault(); subscriptionClicked(e, 'year'); }}>$40 PER YEAR</a>
                  <p className="form-text">Save $8 per year</p>
                </div>
              </div>
            </div>
          </div>
          {subscriptionSelected && validClientSercet() && (
            <Elements 
              stripe={stripePromise} 
              options={{
                clientSecret,
                appearance: { theme: 'stripe' },
              }}
            >
              <SubscriptionForm
                user={context}
                clientSecret={clientSecret}
                subscriptionType={subscriptionType}
                email={accountData.email}
                handleToUpdate={handleToUpdate}
                subscriptionSelected={subscriptionSelected}
              />
            </Elements>
          )}
        </BlockUI>
      )}
    </>
  );
};

const StripePaymentStep = withUser(SubscriptionForm);

export default CampsiteProPage;
export { StripePaymentStep, SubscriptionForm };
